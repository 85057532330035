export default [
    {
        path: "/VGM",
        component: () => import("@/views/VGM/Draft"),
        meta:{activeMenu:'/VGM'}
    },
    {
        path: "/VGM/all",
        component: () => import("../../views/VGM/AllVGM"),
        meta: {activeMenu: '/VGM'}
    },
    {
        path: "/VGM/new",
        name: "CreateVGM",
        component: () => import("@/views/VGM/components/AddVGMDialog.vue"),
        meta:{activeMenu:'/VGM'}
    },
    {
        path: "/VGM/wait",
        component: () => import("@/views/VGM/WaitSend"),
        meta:{activeMenu:'/VGM'}
    },
    {
        path: "/VGM/already",
        component: () => import("@/views/VGM/AlreadySend"),
        meta:{activeMenu:'/VGM'}
    },
    {
        path: "/VGM/uandd",
        component: () => import("@/views/VGM/UpdateDelete"),
        meta:{activeMenu:'/VGM'}
    },
    {
        path: "/VGM/guide/agreement",
        component: () => import("@/views/VGM/guide/Agreement.vue"),
        meta: {activeMenu: '/VGM'}
    },
    {
        path: "/VGM/guide/book",
        component: () => import("@/views/VGM/guide/Book.vue"),
        meta: {activeMenu: '/VGM'}
    },
];
