<!-- 开屏广告 -->
<template>
    <div>
        <el-dialog
            :footer="null"
            :close-on-press-escape="false"
            append-to-body
            :title="null"
            :show-close="false"
            :visible="visible"
            :close-on-click-modal="false"
            class="open-ads"
            width="65vw"
            center            
            custom-class="custom-open-ads"
        >
            <div class="wrapper">
                <div class="img-wrapper">
                    <div class="close-btn">
                        金牌供应商
                        <span class="small-font">
                            {{ time ? time + "s" : "" }}
                        </span>
                    </div>
                    <el-image
                        :src="showAdsInfo.url"
                        alt=""
                        class="img"
                        @click="handleDetail"
                        @load="handleImgLoad"
                    />
                </div>
            </div>
        </el-dialog>
        <el-drawer
            title="详情"
            :visible.sync="pdfVisible"
            :withHeader="false"
            size="50%"
            append-to-body
            @close="handleAdClose"
        >
            <div class="bar">
                <DialogFooter
                    @confirm="handlePre()"
                    @cancel="handleNext()"
                    showTab="NB"
                    :confirmOptions="{
                        size: 'small',
                    }"
                    :cancelOptions="{
                        size: 'small',
                    }"
                    cancelText="下一个"
                    style="gap: 10px; justify-content: flex-start"
                    confirmText="上一个"
                    top="0"
                ></DialogFooter>
                <DialogFooter
                    @confirm="goToDetail()"
                    @cancel="handleClose"
                    showTab="NB"
                    :confirmOptions="{
                        size: 'small',
                    }"
                    :cancelOptions="{
                        size: 'small',
                    }"
                    cancelText="关闭"
                    style="gap: 10px; justify-content: flex-end"
                    confirmText="网页显示"
                    top="0"
                ></DialogFooter>
            </div>
            <PdfView
                :url="adsList[curShowIndex].pdfUrl"
                style="height: calc(100% - 50px)"
            />
        </el-drawer>
        <div
            class="small-window"
            v-if="!visible && !pdfVisible && smallVisible"
        >
            <div class="small-window-container">
                <div class="title">
                    金牌供应商
                    <span class="close-btn" @click="handleCloseSmallWindow">
                        X
                    </span>
                </div>
                <img
                    :src="showAdsInfo.url"
                    alt=""
                    class="img"
                    @click="handleDetail"
                />
            </div>
        </div>
    </div>
</template>
<script>
// 打开详情的key
const OPEN_KEY = `open_ads`;
import PdfView from "../common/PdfView.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import { saveConfig, getConfig, adClick, adClose } from "@/api/common";
import { apiCallback } from "@/utils";
import { getOpenAdsConfig, DEFAULT_CUR_DETAIL_COUNT } from "./index";
export default {
    name: "OpenAds",
    components: {
        PdfView,
        DialogFooter,
    },
    data() {
        return {
            visible: false,
            adsList: [
                {
                    title: "上海隽硕国际货物运输代理有限公司",
                    key: 'junshuo',
                    url: require("./assets/junshuo.png"),
                    pdfUrl: "https://rocket-web-server.oss-cn-hangzhou.aliyuncs.com/static/fangcangwang/ads/%E9%9A%BD%E7%A1%95.pdf",
                },
                {
                    title: "义乌市文鳐报关代理有限公司",
                    key: 'wenyao',
                    url: require("./assets/wenyao.png"),
                    pdfUrl: "https://rocket-web-server.oss-cn-hangzhou.aliyuncs.com/static/fangcangwang/ads/%E6%96%87%E9%B3%90.pdf",
                },
            ],
            showAdsInfo: {
                url: "",
                pdfUrl: "",
                title: "",
            },
            time: 3,
            pdfVisible: false,
            curDetailCount: {
                ...DEFAULT_CUR_DETAIL_COUNT,
            },
            smallVisible: true,
            curShowIndex: 0,
            showDetailAdId: "", // 点击显示广告详情后，记录的广告id
            showDetailStartTime: 0, // 点击显示广告详情，记录的时间
        };
    },
    created() {
        this.calNeedShowAds();
    },
    methods: {
        handleImgLoad() {
            // 计算需要显示的广告
            this.time = 3;
            this.timer = setInterval(() => {
                this.time--;
                if (this.time === 0) {
                    clearInterval(this.timer);
                    this.visible = false;
                }
            }, 1000);
        },
        open() {
            // 计算需要显示的广告
            this.visible = true;
        },
        // 格式化数据(默认值)
        formatData() {
            this.showAdsInfo = this.adsList[0];
            this.curShowIndex = 0;
            this.handleMarkDetail();
            this.smallVisible = true;
        },
        // 计算需要显示的广告
        calNeedShowAds() {
            // 获取当前查看的次数
            try {
                getOpenAdsConfig()
                    .then((res) => {
                        // 计算次数最少的公司名称
                        let min = -1;
                        // 次数最少的公司名称
                        let minTitle = "";
                        const newData = Object.entries(res);
                        this.curDetailCount = res;
                        newData.forEach(([key, value]) => {
                            if (min === -1) {
                                min = value;
                                minTitle = key;
                            } else {
                                if (value < min) {
                                    min = value;
                                    minTitle = key;
                                }
                            }
                        });
                        const findAds = this.adsList.find((item, index) => {
                            const isFit = item.title === minTitle;
                            if (isFit) {
                                this.curShowIndex = index;
                            }
                            return isFit;
                        });
                        this.showAdsInfo = findAds ?? this.adsList[0];
                        if (!findAds) {
                            this.curShowIndex = 0;
                        }
                        this.handleMarkDetail();
                    })
                    .catch((e) => {
                        this.formatData()
                    });
            } catch (e) {
                this.formatData()
            }
        },
        handleCloseSmallWindow() {
            this.smallVisible = false;
        },
        handleClose() {
            if (this.time) return;
            this.visible = false;
        },
        // 标记看某个广告的次数
        handleMarkDetail() {
            // 得到当前的数据
            this.$nextTick(() => {
                this.curDetailCount[this.showAdsInfo["title"]]++;
                saveConfig({
                    dictKey: OPEN_KEY,
                    dictValue: JSON.stringify(this.curDetailCount),
                });
            });
        },
        // 广告点击
        handleAdClick() {
            this.showDetailAdId = "";
            this.showDetailStartTime = new Date().getTime();;
            adClick({
                adName: this.showAdsInfo.title,
            }).then(res => {
                const data = apiCallback(res);
                this.showDetailAdId = data;
            });
        },

        handleDetail() {
            clearInterval(this.timer);
            this.visible = false;
            this.pdfVisible = true;
            this.handleAdClick();
        },
        goToDetail() {
            window.open(this.adsList[this.curShowIndex].pdfUrl);
            this.handleAdClose(true);
        },
        // 处理上一个，下一个
        handleNext() {
            if (this.curShowIndex === this.adsList.length - 1) {
                this.curShowIndex = 0;
            } else {
                this.curShowIndex++;
            }
            this.showAdsInfo = this.adsList[this.curShowIndex];
            this.$nextTick(() => {
                this.handleAdClose().then(() => {
                    this.handleAdClick();
                });
            });
        },
        handlePre() {
            if (this.curShowIndex === 0) {
                this.curShowIndex = this.adsList.length - 1;
            } else {
                this.curShowIndex--;
            }
            this.showAdsInfo = this.adsList[this.curShowIndex];
            this.$nextTick(() => {
                this.handleAdClose().then(() => {
                    this.handleAdClick();
                });
            });
        },
        // 广告关闭或切换
        handleAdClose(newPageAd = false) {
            return new Promise((resolve, reject) => { 
                if (this.showDetailAdId) {
                    // 点击的时候记录成功拿到id才执行此处逻辑
                    const endTime = new Date().getTime();
                    const stayTime = endTime - this.showDetailStartTime;
                    adClose({
                        adId: this.showDetailAdId,
                        stayTime: newPageAd ? 0 : stayTime,
                    }).finally(() => { 
                        this.showDetailAdId = "";
                        this.showDetailStartTime = 0;
                        resolve();
                    });
                } else {
                    resolve();
                }
            })
        },
        handleClose() {
            this.handleAdClose()
            this.pdfVisible = false;
        },
    },
};
</script>
<style lang="stylus">
.open-ads .el-dialog__body{
    padding:0;

}

.open-ads .el-dialog__header{
    padding:0
}
</style>

<style scoped lang="stylus">
@keyframes shake {
    10% {
        transform: rotate(15deg);
      }
      20% {
        transform: rotate(-10deg);
      }
      30% {
        transform: rotate(5deg);
      }
      40% {
        transform: rotate(-5deg);
      }
      50%,100% {
        transform: rotate(0deg);
      }
}

.wrapper{
    position: relative; width: 100%;
    .img-wrapper{
        .img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            max-height:80vh;
            border-radius:25px;
        }
    }
    .close-btn{
        position:absolute;
        right:20px;
        top:10px;
        z-index:999;
        color:#b87100;
        padding:0px 8px;
        border:1px solid #b87100;
        font-size:12px;
        text-align:center;
        cursor:pointer;
        .small-font{
            font-size:10px;
        }
    }
}

.bar{
    padding:5px 10px;
    display:flex;
    justify-content:space-between;
}



.small-window{
    position:fixed;
    bottom:40px;
    left:30px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    cursor:pointer;
    animation: shake 2.5s .15s linear infinite;
    &:hover{
        animation-play-state: paused;
        transform: rotate(0deg);
    }
    .title{
        padding-left:5px;
        font-size:12px;
        margin-bottom:5px;
        color:#b87100;
        font-weight:bold;
    }
    .close-btn{
        padding-left:3px;
        color:#333;
    }
    .img{
        width:85px;
    }
}
</style>
