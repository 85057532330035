// 宁波版物流跟踪订阅列表(历史记录)
const resetTableNB = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "75",
    },
    {
        prop: "clientCompanyName",
        label: "客户公司",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "billNo",
        label: "提单号",
        show: true,
        fixed: "left",
        width: "220",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxPosition",
        label: "集卡定位",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    // 提空箱暂时隐藏，勿删
    // {
    //   prop: "takeout",
    //   label: "提空箱",
    //   width: "100",
    //   show: true,
    // },
    {
        prop: "boxArrivalCount",
        label: "进港/舱单",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "errorValue",
        label: "毛重误差",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "preFlag",
        label: "预配舱单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ifcsumFlag",
        label: "清洁舱单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "cpcodeFlag",
        label: "码头运抵",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "customFlag",
        label: "海放",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "sldFlag",
        label: "三联单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxCusmov",
        label: "海关查验",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "nppStatus",
        label: "码放",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dlPortCode",
        label: "装卸货港",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "wharf",
        label: "码头",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnOwner",
        label: "船公司",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closeTime",
        label: "截关时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portCloseTime",
        label: "截单时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyBeginTime",
        label: "进箱开始",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyEndTime",
        label: "进箱截止",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivalTime",
        label: "预计抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaDepartureTime",
        label: "预计离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "loadTime",
        label: "装船时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivalTime",
        label: "实际抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "departureTime",
        label: "实际离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 上海版物流跟踪订阅列表
const sResetTable = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "75",
    },
    {
        prop: "clientCompanyName",
        label: "客户公司",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "billNo",
        label: "提单号",
        show: true,
        fixed: "left",
        width: "220",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxArrivalManifest",
        label: "预录/进港/舱单",
        width: "130",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "errorValueCheck",
        label: "进港/舱单比对",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "preFlag",
        label: "预配舱单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "cpcodeFlag",
        label: "码头运抵",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "customFlag",
        label: "海放",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "nppStatus",
        label: "码放",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "wharf",
        label: "码头",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnOwner",
        label: "船公司",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyBeginTime",
        label: "进箱开始",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyEndTime",
        label: "进箱截止",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivalTime",
        label: "预计抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaDepartureTime",
        label: "预计离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivalTime",
        label: "实际抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "departureTime",
        label: "实际离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 青岛版物流跟踪订阅列表
const qdResetTable = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "75",
    },
    {
        prop: "clientCompanyName",
        label: "客户公司",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "billNo",
        label: "提单号",
        show: true,
        fixed: "left",
        width: "220",
    },
    {
        prop: "blnoMain",
        label: "舱单主单号",
        show: true,
        width: "200",
    },
    {
        prop: "webname",
        label: "场站",
        show: true,
        fixed: "left",
        width: "150",
    },
    {
        prop: "wharfCode",
        label: "查询码头",
        show: true,
        width: "120",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "提箱",
        prop: "inputCtns",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },

    {
        label: "返场",
        prop: "backCtns",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港",
        prop: "cargoCtns",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     label: "VGM",
    //     prop: "vgm",
    //     width: 120,
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        label: "舱单",
        prop: "preFlag",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "海放",
        prop: "customFlag",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "目的港",
        prop: "destinationPort",
        width: 120,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "码头",
        prop: "wharf",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "船司",
        prop: "shipCompany",
        width: 120,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港开始(开港)时间",
        prop: "jgApplyBeginTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港结束(截港)时间",
        prop: "jgApplyEndTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "收箱开始时间",
        prop: "ctnApplyBeginTime",
        width: 120,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "收箱结束时间",
        prop: "ctnApplyEndTime",
        width: 120,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivalTime",
        label: "预计抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaDepartureTime",
        label: "预计离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivalTime",
        label: "实际抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "departureTime",
        label: "实际离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
];

// 天津版物流跟踪订阅列表
const tjResetTable = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "75",
    },
    {
        prop: "clientCompanyName",
        label: "客户公司",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "billNo",
        label: "提单号",
        show: true,
        fixed: "left",
        width: "220",
    },
    {
        prop: "blnoMain",
        label: "舱单主单号",
        show: true,
        width: "200",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "preFlag",
        label: "预配舱单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxArrivalManifest",
        label: "进港",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "海放",
        prop: "customFlag",
        width: 80,
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "cpcodeFlag",
    //     label: "码头运抵",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        label: "船司",
        prop: "shipCompany",
        width: 120,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "码头",
        prop: "wharf",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "nppStatus",
    //     label: "码放",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    // {
    //     prop: "dlPortCode",
    //     label: "装卸货港",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    // {
    //     prop: "boxArrivalManifest",
    //     label: "进港/舱单",
    //     width: "100",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    // {
    //     prop: "errorValueCheck",
    //     label: "进港/舱单比对",
    //     width: "120",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        label: "集港开始(开港)时间",
        prop: "jgApplyBeginTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港结束(截港)时间",
        prop: "jgApplyEndTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 厦门版物流跟踪订阅列表
const xmResetTable = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "75",
    },
    {
        prop: "clientCompanyName",
        label: "客户公司",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "billNo",
        label: "提单号",
        show: true,
        fixed: "left",
        width: "200",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxArrivalManifest",
        label: "进港",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "舱单",
        prop: "preFlag",
        width: 50,
        show: true,
        sortable: false,
        resizable: true,
    },

    // {
    //     prop: "errorValueCheck",
    //     label: "进港/舱单比对",
    //     width: "120",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "customFlag",
        label: "海放",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "nppStatus",
        label: "码放",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dlPortCode",
        label: "装卸货港",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "码头",
        prop: "wharf",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipCompany",
        label: "船司",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },

    {
        prop: "wharfRemark",
        label: "码头反馈",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closeTime",
        label: "截关时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portCloseTime",
        label: "截单时间",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyBeginTime",
        label: "进箱开始",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyEndTime",
        label: "进箱截止",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivalTime",
        label: "预计抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaDepartureTime",
        label: "预计离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "loadTime",
    //     label: "装船时间",
    //     width: "110",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "arrivalTime",
        label: "实际抵港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "departureTime",
        label: "实际离港",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
];

// 深圳版物流跟踪订阅列表
const szResetTable = [
    {
        prop: "area",
        label: "地区",
        show: true,
        fixed: "left",
        width: "100",
    },
    {
        prop: "billNo",
        label: "S/O号",
        show: true,
        fixed: "left",
        width: "200",
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        show: true,
        fixed: "left",
        width: "250",
    },
    {
        prop: "shipPosition",
        label: "船舶定位",
        show: true,
        fixed: "left",
        width: "80",
    },
    {
        prop: "remark",
        label: "备注",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxArrivalManifest",
        label: "进港",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "loadingPort",
        label: "装/卸货港",
        width: "160",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "码头",
        prop: "giName",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipCompany",
        label: "船司",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "tcStatus",
        label: "状态",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "bkDatetimeFlag",
        label: "订舱",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "firstPrintDatetimeFlag",
        label: "打单",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "emptyOutFlag",
        label: "提空",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "giNameFlag",
        label: "还重",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "vgmDatetimeFlag",
        label: "VGM",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "manifestFlag",
        label: "舱单申报",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "customPassFlag",
        label: "海放",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "lsDatetimeFlag",
        label: "装船",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "hasDepartureInfoFlag",
        label: "离岸信息",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 船期订阅列表
const shipTable = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "companyid",
        label: "停靠码头",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "95",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closedate",
        label: "截关时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portclosedate",
        label: "截单时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplybegindate",
        label: "进箱开始",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplyenddate",
        label: "进箱截止",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivedDate",
        label: "预计抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaSailingDate",
        label: "预计离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "loadTime",
    //     label: "装船时间",
    //     width: "105",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "arrivaldate",
        label: "实际抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargedate",
        label: "实际离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 上海船期订阅列表
const shipTableSH = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "companyid",
        label: "停靠码头",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "95",
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "closedate",
    //     label: "截关时间",
    //     width: "105",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    // {
    //     prop: "portclosedate",
    //     label: "截单时间",
    //     width: "105",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "ctnapplybegindate",
        label: "进箱开始",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplyenddate",
        label: "进箱截止",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivedDate",
        label: "预计抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaSailingDate",
        label: "预计离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     prop: "loadTime",
    //     label: "装船时间",
    //     width: "105",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "arrivaldate",
        label: "实际抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargedate",
        label: "实际离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 青岛船期订阅列表
const shipTableQD = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "wharfCode",
        label: "查询码头",
        show: true,
        width: "120",
    },
    {
        prop: "companyid",
        label: "停靠码头",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "95",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港开始(开港)时间",
        prop: "jgApplyBeginTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港结束(截港)时间",
        prop: "jgApplyEndTime",
        width: 150,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplybegindate",
        label: "收箱开始",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplyenddate",
        label: "收箱结束",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivedDate",
        label: "预计抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaSailingDate",
        label: "预计离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivaldate",
        label: "实际抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargedate",
        label: "实际离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
];
// 天津船期订阅列表
const shipTableTJ = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "companyid",
        label: "停靠码头",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "210",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港开始(开港)时间",
        prop: "jgApplyBeginTime",
        width: "180",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        label: "集港结束(截港)时间",
        prop: "jgApplyEndTime",
        width: "180",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: 140,
        show: true,
        sortable: false,
        resizable: true,
    },
];

// 厦门船期订阅列表
const shipTableXM = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipCompany",
        label: "船司",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "companyid",
        label: "停靠码头",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "125",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closedate",
        label: "截关时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portclosedate",
        label: "截单时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplybegindate",
        label: "进箱开始",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplyenddate",
        label: "进箱截止",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivedDate",
        label: "预计抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaSailingDate",
        label: "预计离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivaldate",
        label: "实际抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargedate",
        label: "实际离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
];
const shipTableSZ = [
    {
        prop: "index",
        type: "index",
        label: "序号",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "place",
        label: "地区",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxvv",
        label: "船名/航次",
        width: "350",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipLocation",
        label: "船舶定位",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "shipCompany",
        label: "船司",
        width: 100,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "companyid",
        label: "停靠码头",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chndescription",
        label: "中文船名",
        width: "125",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closedate",
        label: "截关时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portclosedate",
        label: "截单时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplybegindate",
        label: "进箱开始",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnapplyenddate",
        label: "进箱截止",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivedDate",
        label: "预计抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaSailingDate",
        label: "预计离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivaldate",
        label: "实际抵港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargedate",
        label: "实际离港",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "createTime",
        label: "订阅时间",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
]
// 物流实时查询
const queryTable = [
    // {
    //     prop: "boxBlno", // 对应列内容的字段名
    //     label: "提单号",// 显示的标题
    //     width: "170",// 对应列的宽度
    //     show: true,// 展示与隐藏
    //     sortable: false, // 对应列是否可以排序
    //     resizable: true, // 对应列是否可以通过拖动改变宽度（需要在 el-table 上设置 border 属性为真）
    // },
    // {
    //     prop: "serialnum",
    //     label: "序号",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    // 提空箱功能暂时隐藏，勿删
    // {
    //   prop: "outdoor_time",
    //   label: "提箱时间",
    //   width: "140",
    //   show: true,
    // },
    // {
    //   prop: "yardname",
    //   label: "提箱地点",
    //   width: "140",
    //   show: true,
    // },
    {
        prop: "arrivalTime",
        label: "进门时间",
        width: "135",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "Vesselnamevoyage",
        label: "船名航次",
        width: "240",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "blno",
        label: "提单号",
        width: "240",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnNo",
        label: "集装箱号",
        width: "140",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "sealNo",
        label: "铅封号",
        width: "140",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "sizetype",
        label: "箱型",
        width: "70",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "grossWeight",
        label: "货毛重",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "cpcode",
        label: "码头",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "dischargeport",
        label: "卸货港",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ifcsum_flag",
        label: "清洁舱单",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "cpcode_flag",
        label: "运抵",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "custom_flag",
        label: "海放",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "sld_flag",
        label: "三联单",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "pass_flag",
        label: "码放状态",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "boxCusmov",
        label: "查验",
        width: "80",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "remark",
        label: "码头反馈",
        width: "95",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "loadTime",
        label: "装船时间",
        width: "135",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "sailingTime",
        label: "离泊时间",
        width: "135",
        show: true,
        sortable: false,
        resizable: true,
    },
];

const queryTable2 = [
    // {
    //     prop: "boxBlno", // 对应列内容的字段名
    //     label: "提单号",// 显示的标题
    //     width: "170",// 对应列的宽度
    //     show: true,// 展示与隐藏
    //     sortable: false, // 对应列是否可以排序
    //     resizable: true, // 对应列是否可以通过拖动改变宽度（需要在 el-table 上设置 border 属性为真）
    // },
    // {
    //     prop: "serialnum",
    //     label: "序号",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "wharf",
        label: "集靠码头",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "vesselsName",
        label: "英文船名",
        width: "210",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "chnVesselsName",
        label: "中文船名",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "voyage",
        label: "航次",
        width: "100",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "direction",
        label: "进出口",
        width: "70",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "vesselsUn",
        label: "UN代码",
        width: "110",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyBeginTime",
        label: "进箱开始",
        width: "150",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnApplyEndTime",
        label: "进箱截止",
        width: "150",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portCloseTime",
        label: "截单时间",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "closeTime",
        label: "截关时间",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaArrivalTime",
        label: "预计抵港",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "etaDepartureTime",
        label: "预计离港",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "arrivalTime",
        label: "实际抵港",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "departureTime",
        label: "实际离港",
        width: "220",
        show: true,
        sortable: false,
        resizable: true,
    },
];

const queryTable3 = [
    // {
    //     prop: "boxBlno", // 对应列内容的字段名
    //     label: "提单号",// 显示的标题
    //     width: "170",// 对应列的宽度
    //     show: true,// 展示与隐藏
    //     sortable: false, // 对应列是否可以排序
    //     resizable: true, // 对应列是否可以通过拖动改变宽度（需要在 el-table 上设置 border 属性为真）
    // },
    // {
    //     prop: "serialnum",
    //     label: "序号",
    //     width: "80",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "blno",
        label: "提单号",
        width: 220,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnInfoArr",
        label: "箱号 / 箱型",
        width: 180,
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "vesselsName",
        label: "船名",
        show: true,
        width: 200,
        sortable: false,
        resizable: true,
    },
    {
        prop: "voyage",
        label: "航次",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "vesselsUn",
        label: "UN码",
        show: true,
        width: 100,
        sortable: false,
        resizable: true,
    },
    {
        prop: "portCode",
        label: "关区",
        show: true,
        width: 120,
        sortable: false,
        resizable: true,
    },
    {
        prop: "totalPackNum",
        label: "件数",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "totalGrossWt",
        label: "毛重",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },

    {
        prop: "listStatText",
        label: "预配状态",
        width: "240",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "saveTime",
        label: "更新时间",
        width: "160",
        show: true,
        sortable: false,
        resizable: true,
    },
];

const rUserDataList = [
    {
        prop: "name",
        label: "姓名",
        width: "100",
    },
    {
        prop: "phone",
        label: "电话",
        width: "200",
    },
    {
        prop: "address",
        label: "地址",
        width: "220",
    },
    {
        prop: "countrycode",
        label: "国家/地区代码",
        width: "120",
    },
    {
        prop: "aeocode",
        label: "AEO代码",
        width: "122",
    },
    {
        prop: "detailpeople",
        label: "具体联系人",
        width: "100",
    },
    {
        prop: "detailphone",
        label: "联系人电话",
        width: "200",
    },
];
const sUserDataList = [
    {
        prop: "name",
        label: "姓名",
        width: "100",
    },
    {
        prop: "phone",
        label: "电话",
        width: "200",
    },
    {
        prop: "address",
        label: "地址",
        width: "220",
    },
    {
        prop: "countrycode",
        label: "国家/地区代码",
        width: "120",
    },
    {
        prop: "aeocode",
        label: "AEO代码",
        width: "122",
    },
];
const manifestTable = [
    {
        // prop: "theSendTime",
        prop: "createtime",
        label: "创建时间",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        // prop: "blname",
        prop: "shipVoyage",
        label: "船名航次",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },

    {
        // prop: "allNumbers",
        prop: "totalpackno",
        label: "总件数",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        // prop: "theHeight",
        prop: "totalweight",
        label: "毛重",
        width: "90",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        // prop: "theVolume",
        prop: "totalvolume",
        label: "体积",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "isMerge",
        label: "是否合并品名",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        // prop: "theBoxesNumber",
        prop: "ctnsnum",
        label: "集装箱数量",
        width: "105",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "ctnAndSealsize",
        label: "箱封号",
        width: "120",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        // prop: "theTypeNumbers",
        prop: "goodsnum",
        label: "品名数量",
        width: "50",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "stoptime",
        label: "截单时间",
        width: "150",
        show: true,
        sortable: false,
        resizable: true,
    },
    {
        prop: "manifestRemark",
        label: "备注",
        width: "200",
        show: true,
        sortable: false,
        resizable: true,
    },
    // {
    //     // prop: "allTime",
    //     prop: "totaltime",
    //     label: "总耗时",
    //     width: "150",
    //     show: true,
    //     sortable: false,
    //     resizable: true,
    // },
    {
        prop: "score",
        label: "我的评价",
        width: "150",
        show: true,
        sortable: false,
        resizable: true,
    },
];
const shanghaiAlready = [
    {
        prop: "id",
        label: "发送编号",
        width: "180",
    },
    {
        prop: "vesselname",
        label: "船名",
        width: "200",
    },
    {
        prop: "voyageno",
        label: "航次",
        width: "180",
    },
    {
        prop: "billno",
        label: "主提单号",
        width: "220",
    },
    {
        prop: "status",
        label: "状态",
        width: "120",
    },
    {
        prop: "subject",
        label: "处理摘要",
        width: "200",
    },
    {
        prop: "billnozhuizhi",
        label: "装载舱单回执",
        width: "180",
    },
    {
        prop: "billnohaiguan",
        label: "海关部署舱单",
        width: "180",
    },
    {
        prop: "sendtime",
        label: "发送时间",
        width: "220",
    },
];
const shanghaiCounterDelete = [
    {
        prop: "id",
        label: "柜台删单编号",
        width: "220",
    },
    {
        prop: "billno",
        label: "提单号",
        width: "220",
    },
    {
        prop: "remarks",
        label: "备注",
        width: "210",
    },
    {
        prop: "money",
        label: "删单费",
        width: "180",
    },
    {
        prop: "status",
        label: "状态",
        width: "150",
    },
    {
        prop: "message",
        label: "处理摘要",
        width: "220",
    },
    {
        prop: "submittime",
        label: "提交时间",
        width: "220",
    },
];
const shanghaiUpdateDelete = [
    {
        prop: "updateid",
        label: "删改编号",
        width: "200",
    },
    {
        prop: "vesselname",
        label: "船名",
        width: "220",
    },
    {
        prop: "voyageno",
        label: "航次",
        width: "220",
    },
    {
        prop: "billno",
        label: "主提单号",
        width: "220",
    },
    {
        prop: "completionstatus",
        label: "状态",
        width: "150",
    },
    {
        prop: "message",
        label: "处理摘要",
        width: "220",
    },
    {
        prop: "createTime",
        label: "提交时间",
        width: "220",
    },
];
/**
 * 上海舱单列表表头
 */
const manifestTableTitleList = [
    {
        prop: "subject",
        label: "客户主题",
        width: "280",
        fixed: "left",
        align: "left",
        status: [0],
    },
    {
        prop: "billno",
        label: "主提单号",
        width: "210",
        fixed: "left",
        align: "left",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "billNos",
        label: "分提单号",
        align: "left",
        width: "210",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "ctnNos",
        label: "箱号",
        width: "120",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "completionstatus",
        label: "舱单所处位置",
        width: "120",
        status: [3],
    },
    {
        prop: "fcwStatusStr",
        label: "系统信息",
        width: "280",
        status: [3],
    },
    {
        prop: "isCustomerApplyFinish",
        label: "系统处理状态",
        width: "180",
        status: [3],
    },
    {
        prop: "shipAgentBillStatusStr",
        label: "船代回执",
        width: "280",
        status: [-2, 2, 3],
    },
    {
        prop: "customsReceiptStatusStr",
        label: "海关回执",
        width: "220",
        status: [-2, 2, 3],
    },
    {
        prop: "vgmReceiptMsg",
        label: "VGM回执",
        width: "200",
        status: [-2, 2, 3],
    },
    {
        prop: "loadingReplayStatusStr",
        label: "装载舱单回执",
        width: "180",
        status: [2, 3],
    },
    {
        prop: "operator",
        label: "操作人",
        width: "100",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "companyname",
        label: "客户公司",
        width: "180",
        status: [3],
    },
    {
        prop: "remark",
        label: "备注",
        width: "180",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "vesselvoyage",
        label: "船名 / 航次",
        width: "300",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    // {
    //     prop: "vesselname",
    //     label: "箱号",
    //     width: "180",
    // },
    // {
    //     prop: "voyageno",
    //     label: "航次",
    //     width: "180",
    // },
    {
        prop: "scompany",
        label: "船公司",
        width: "180",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },

    {
        prop: "webtype",
        label: "船代",
        width: "100",
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    {
        prop: "total",
        label: "总件数 / 毛重 / 体积",
        width: 200,
        status: [-3, -2, -1, 0, 1, 2, 3],
    },
    // {
    //     prop: "totalweight",
    //     label: "总毛重",
    //     width: "100",
    // },
    // {
    //     prop: "totalvolume",
    //     label: "总体积",
    //     width: "100",
    // },
    {
        prop: "ctnApplyBeginTime",
        label: "进箱开始",
        width: "100",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "ctnApplyEndTime",
        label: "进箱截止",
        width: "100",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "createtime",
        label: "创建时间",
        width: "100",
        status: [3],
    },
    {
        prop: "updatetime",
        label: "最后修改",
        width: "100",
        status: [0, 1, 3],
    },
    {
        prop: "sendTime",
        label: "发送时间",
        width: "100",
        status: [2, 3],
    },
    {
        prop: "deleteTime",
        label: "删除时间",
        width: "100",
        status: [-3, -2, 3],
    },
    {
        prop: "validation",
        label: "异常状态",
        width: "180",
        status: [0, 3],
    },
];
const vgmTableHeader = [
    {
        prop: "billNo",
        label: "提单号",
        width: "240",
        fixed: "left",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "vgmReceiptMsg",
        label: "船公司回执",
        width: "200",
        status: [2, 3],
    },
    {
        prop: "remark",
        label: "备注",
        width: "200",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "completionstatus",
        label: "舱单所处位置",
        width: "120",
        status: [3],
    },
    {
        prop: "companyName",
        label: "客户公司",
        width: "180",
        status: [3],
    },
    {
        prop: "ctnNoList",
        label: "箱号",
        width: "150",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "vgmGrossWeightList",
        label: "VGM重量(KGS)",
        width: "140",
        status: [-2, -1, 0, 1, 2, 3],
    },
    // {
    //     prop: "ctnNo",
    //     label: "箱号",
    //     width: "200",
    //     status: [-2, -1, 0, 1, 2],
    // },
    {
        prop: "vesselvoyage",
        label: "船名 / 航次",
        width: "250",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "shipCompany",
        label: "船公司",
        width: "200",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "wharf",
        label: "码头",
        width: "200",
        status: [-2, -1, 0, 1, 2, 3],
    },
    {
        prop: "updateTime",
        label: "修改时间",
        width: "200",
        status: [0, 1, 3],
    },
    {
        prop: "sendTime",
        label: "发送时间",
        width: "200",
        status: [2, 3],
    },
    {
        prop: "deleteTime",
        label: "删除时间",
        width: "200",
        status: [-2, -1, 3],
    },

];
const vgmContainerTable = [
    {
        prop: "ctnNo",
        label: "*箱号",
        width: "200",
        placeholder: "箱号必须为4位字母+7位数字",
    },
    {
        prop: "vgmMethod",
        label: "*称重方法",
        width: "200",
        placeholder: "请输入",
    },
    {
        prop: "vgmTareWeight",
        label: "*箱皮重(KGS)",
        width: "200",
        placeholder: "请输入",
    },
    {
        prop: "vgmNetWeight",
        label: "*箱内货重(KGS)",
        width: "200",
        placeholder: "请输入",
    },
    {
        prop: "vgmGrossWeight",
        label: "*VGM重量(KGS)",
        width: "200",
        placeholder: "请输入",
    },
    {
        prop: "vgmLocation",
        label: "*称重方",
        placeholder: "请输入",
    },
    {
        prop: "vgmAddress",
        label: "*称重方地址",
        placeholder: "请输入",
    },
    {
        prop: "vgmSign",
        label: "*签名",
        width: "200",
        placeholder: "请输入",
    },
];
const shanghaiShipAgent = [
    {
        value: 1,
        label: "上海外代",
    },
    {
        value: 2,
        label: "SWB-海运单",
    },
    {
        value: 3,
        label: "TER-电放",
    },
];
const scompany = [
    {
        value: "ONE",
        label: "ONE—海洋网联",
        remark: "船公司ONE—海洋网联的提单头通常为ONE",
    },
    {
        value: "ANL",
        label: "ANL—澳航",
    },
    {
        value: "APL",
        label: "APL—美国总统",
    },
    {
        value: "ASL",
        label: "ASL—亚海",
        remark: "船公司ASL—亚海的提单头通常为ASH",
    },
    {
        value: "BAL",
        label: "BAL—博亚",
        remark: "船公司BAL—博亚的提单头通常为28",
    },
    {
        value: "BLPL",
        label: "BLPL—新加坡私立",
    },
    {
        value: "BSL",
        label: "BSL—巴拉基",
    },
    {
        value: "BTL",
        label: "BTL—孟虎航运公司",
        remark: "船公司BTL—孟虎航运公司的提单头通常为BTL",
    },
    {
        value: "BTLS",
        label: "BTLS—孟虎上海",
        remark: "船公司BTLS—孟虎上海的提单头通常为FMTSHA",
    },
    {
        value: "CL",
        label: "CL—中通",
        remark: "船公司CL—中通的提单头通常为CSH",
    },
    {
        value: "CMA",
        label: "CMA—达飞",
    },
    {
        value: "CNC",
        label: "CNC—正利",
        remark: "船公司CNC—正利的提单头通常为AC",
    },
    {
        value: "COH",
        label: "COH—京汉",
        remark: "船公司COH—京汉的提单头通常为COH",
    },
    {
        value: "CSL",
        label: "CSL—晶品",
        remark: "船公司CSL—晶品的提单头通常为CSL",
    },
    {
        value: "CST",
        label: "CST—宝华",
        remark: "船公司CST—宝华的提单头通常为CB",
    },
    {
        value: "CUL",
        label: "CUL—中联运通",
        remark: "船公司CUL—中联运通的提单头通常为CUL",
    },
    {
        value: "DY",
        label: "DY—东映",
        remark: "船公司DY—东映的提单头通常为PCSLS",
    },
    {
        value: "ESL",
        label: "ESL—阿联酋",
        remark: "船公司ESL—阿联酋的提单头通常为ESL、SHNH、SHNB、SHNA、SHNT、SHNQ",
    },
    {
        value: "EMC",
        label: "EMC—长荣",
        remark: "船公司EMC/EVG—长荣的提单头通常为EGLV",
    },
    {
        value: "EVG",
        label: "EVG—长荣",
        remark: "船公司EMC/EVG—长荣的提单头通常为EGLV",
    },
    {
        value: "FSL",
        label: "FSL—达飞伯辽兹",
    },
    {
        value: "FESCO",
        label: "FESCO—俄远东",
        remark: "船公司FESCO/FSC—俄远东的提单头通常为FBSF、FLC",
    },
    {
        value: "FSC",
        label: "FSC—俄远东",
    },
    {
        value: "GFS",
        label: "GFS—格飞驰",
    },
    {
        value: "HAL",
        label: "HAL—兴亚",
        remark: "船公司HAL—兴亚的提单头通常为HAS",
    },
    {
        value: "HSD",
        label: "HSD/HAM—汉堡南美",
        remark: "船公司HSD/HAM—汉堡南美的提单头通常为1SHA、0SHA、2SHA",
    },
    {
        value: "HAM",
        label: "HAM—汉堡南美",
        remark: "船公司HSD/HAM—汉堡南美的提单头通常为1SHA、0SHA、2SHA",
    },
    {
        value: "HSLI",
        label: "HSLI—韩星海运株式会社",
    },
    {
        value: "HMM",
        label: "HMM—现代",
        remark: "船公司HMM—现代的提单头通常为HDMU",
    },
    {
        value: "HLC",
        label: "HLC/HPL—赫伯罗特",
        remark: "船公司HLC/HPL—赫伯罗特的提单头通常为HLCU",
    },
    {
        value: "HPL",
        label: "HPL—赫伯罗特",
    },
    {
        value: "HLD",
        label: "HLD—海隆达",
        remark: "船公司HLD—海隆达的提单头通常为HLD",
    },
    {
        value: "IAL",
        label: "IAL—运达",
    },
    {
        value: "IDL",
        label: "IDL—印达斯",
    },
    {
        value: "KKC",
        label: "KKC—神原",
        remark: "船公司KKC—神原的提单头通常为KSHA",
    },
    {
        value: "KMTC",
        label: "KMTC—高丽",
        remark: "船公司KMTC/KMT—高丽的提单头通常为KMTC",
    },
    {
        value: "KMT",
        label: "KMT—高丽",
        remark: "船公司KMTC/KMT—高丽的提单头通常为KMTC",
    },
    {
        value: "MAT",
        label: "MAT—美森",
        remark: "船公司MAT—美森的提单头通常为MAT",
    },
    {
        value: "MBF",
        label: "MBF—卡朋特",
        remark: "船公司MBF—卡朋特的提单头通常为MBF",
    },
    {
        value: "MEL",
        label: "MEL—玛丽安娜",
    },
    {
        value: "MIA",
        label: "MIA—香港璟荣船务",
        remark: "船公司MIA—香港璟荣船务的提单头通常为MIA",
    },
    {
        value: "MSK",
        label: "MSK—马士基",
        remark: "船公司MSK/MCC/SAF—马士基的提单头通常为60、1KT、91、59、26、25、24、23、22、21、29、20",
    },
    {
        value: "MCC",
        label: "MCC—马士基",
        remark: "船公司MSK/MCC/SAF—马士基的提单头通常为60、1KT、91、59、26、25、24、23、22、21、29、20",
    },
    {
        value: "SAF",
        label: "SAF—马士基",
        remark: "船公司MSK/MCC/SAF—马士基的提单头通常为60、1KT、91、59、26、25、24、23、22、21、29、20",
    },
    {
        value: "NDS",
        label: "NDS—尼罗河",
    },
    {
        value: "NNS",
        label: "NNS—洋浦良恩",
    },
    {
        value: "NOS",
        label: "NOS—新东",
        remark: "船公司NOS—新东的提单头通常为NOL",
    },
    {
        value: "NSI",
        label: "NSI—宁波远洋",
        remark: "船公司NSI—宁波远洋的提单头通常为NOS",
    },
    {
        value: "NZL",
        label: "NZL—新西兰航运",
        remark: "船公司NZL—新西兰航运的提单头通常为NZL",
    },
    {
        value: "OOCL",
        label: "OOCL—东方海外",
        remark: "船公司OOCL—东方海外的提单头通常为OOLU",
    },
    {
        value: "OEL",
        label: "OEL—东速海运",
    },
    {
        value: "PEL",
        label: "PEL—新加坡",
    },
    {
        value: "PIL",
        label: "PIL—太平",
    },
    {
        value: "PMA",
        label: "PMA—普洱玛",
        remark: "船公司PMA—普洱玛的提单头通常为PMA",
    },
    {
        value: "RCL",
        label: "RCL—宏海箱运",
        remark: "船公司RCL—宏海箱运的提单头通常为SHACB",
    },
    {
        value: "SCO",
        label: "SCO—新加坡海运",
        remark: "船公司SCO—新加坡海运的提单头通常为SECU",
    },
    {
        value: "SCOL",
        label: "SCOL—兴联海",
    },
    {
        value: "SLS",
        label: "SLS—新加坡海领船务",
        remark: "船公司SLS—新加坡海领船务的提单头通常为FCS",
    },
    {
        value: "SMH",
        label: "SMH—司马泰",
    },
    {
        value: "SML",
        label: "SML—森罗",
        remark: "船公司SML—森罗的提单头通常为SHSU",
    },
    {
        value: "SNKO",
        label: "SNKO—长锦",
        remark: "船公司SNKO—长锦的提单头通常为SNKO",
    },
    {
        value: "SNL",
        label: "SNL—中外运",
        remark: "船公司SNL—中外运的提单头通常为SNL",
    },
    {
        value: "SOF",
        label: "SOF—顺发",
    },
    {
        value: "SSL",
        label: "SSL—萨姆达拉",
        remark: "船公司SSL—萨姆达拉的提单头通常为SSL",
    },
    {
        value: "STX",
        label: "STX—泛奥昇",
        remark: "船公司STX/POL—泛奥昇的提单头通常为POB",
    },
    {
        value: "POL",
        label: "POL—泛奥昇",
        remark: "船公司STX/POL—泛奥昇的提单头通常为POB",
    },
    {
        value: "SWIR",
        label: "SWIR—太古",
        remark: "船公司SWIR—太古的提单头通常为CNSHA",
    },
    {
        value: "SWL",
        label: "SWL—盛威航运",
    },
    {
        value: "SUM",
        label: "SUM—萨米特",
    },
    {
        value: "TJM",
        label: "TJM—天津津海",
    },
    {
        value: "TSL",
        label: "TSL—德翔",
        remark: "船公司TSL/TSC—德翔的提单头通常为SX",
    },
    {
        value: "TSC",
        label: "TSC—德翔",
        remark: "船公司TSL/TSC—德翔的提单头通常为SX",
    },
    {
        value: "TYS",
        label: "TYS—太荣",
        remark: "船公司TYS—太荣的提单头通常为TYS",
    },
    {
        value: "UNL",
        label: "UNL—联合航运",
        remark: "船公司UNL—联合航运的提单头通常为UNL",
    },
    {
        value: "WHL",
        label: "WHL—万海",
        blnoRemark: "填so号，例如：WK420P00081",
    },
    {
        value: "WOSCO",
        label: "WOSCO—西联",
    },
    {
        value: "YML",
        label: "YML—阳明",
    },
    {
        value: "ZGXL",
        label: "ZGXL—中谷东海",
    },
    {
        value: "ZIM",
        label: "ZIM—以星",
        remark: "船公司ZIM/GOLD STAR—以星的提单头通常为ZIMU、GOSU",
    },
    {
        value: "GOLD STAR",
        label: "GOLD STAR—以星",
        remark: "船公司ZIM/GOLD STAR—以星的提单头通常为ZIMU、GOSU",
    },
    {
        value: "NSL",
        label: "NSL—南星海运",
    },
];
const goodsDetail = [
    {
        prop: "billno",
        label: "*分提单号",
        width: "180",
        placeholder: "只能是大写字母和数字",
    },
    {
        prop: "ctnno",
        label: "*箱号",
        width: "180",
        placeholder: "只能是大写字母和数字的11位箱号",
    },
    {
        prop: "sealno",
        label: "*封号",
        width: "150",
        placeholder: "28个字符内,只能是大写字母和数字",
    },
    {
        prop: "ctntype",
        label: "*箱型",
        width: "120",
        placeholder: "请选择箱型",
    },
    {
        prop: "boxmark",
        label: "*货主箱标记",
        width: "160",
        placeholder: "请选择",
    },
    {
        prop: "cargoname",
        label: "*英文品名",
        width: "250",
        placeholder: "不允许中文字符,最多256个字符",
    },
    {
        prop: "wraptype",
        label: "*包装单位",
        width: "150",
        placeholder: "请选择",
    },
    {
        prop: "packno",
        label: "*件数",
        width: "100",
        placeholder: "必须是整数",
    },
    {
        prop: "weight",
        label: "*毛重",
        width: "150",
        placeholder: "请输入毛重,只能是数字",
    },
    {
        prop: "volume",
        label: "*体积",
        width: "150",
        placeholder: "请输入体积,只能是数字",
    },
    {
        prop: "mark",
        label: "*唛头",
        width: "150",
        placeholder: "不要输入中文字符",
    },
    // {
    //     prop: "hscode",
    //     label: "HS Code",
    //     width: "150",
    //     placeholder: "多个HS CODE 只需填写一个",
    // },
    {
        prop: "dguncode",
        label: "UN Code(危)",
        width: "140",
        placeholder: "多个危险品混装时,按最高原则填一个",
    },
    {
        prop: "dgclass",
        label: "类别(危)",
        width: "100",
        placeholder: "多个危险品混装时,按最高原则填一个",
    },
    {
        prop: "hazmat",
        label: "危品联系人",
        width: "220",
        placeholder: "最多70个字符",
    },
    {
        prop: "hazmatnum",
        label: "危品联系电话",
        width: "220",
        placeholder: "最多35个字符",
    },
    {
        prop: "email",
        label: "危品联系Email",
        width: "220",
        placeholder: "最多70个字符",
    },
    {
        prop: "fax",
        label: "危品联系传真",
        width: "220",
        placeholder: "最多35个字符",
    },
    {
        prop: "temperature",
        label: "温度",
        width: "100",
    },
    {
        prop: "temperatureUnit",
        label: "温度单位",
        width: "100",
    },
    {
        prop: "bstatus",
        label: "空箱",
        width: "100",
    },
    // {
    //     prop: "isHazardous",
    //     label: "是否有危险品信息",
    //     width: "200",
    // },
];
const shanghaiSN = [
    {
        prop: "nickName",
        label: "简称",
        width: "180",
    },
    {
        prop: "name",
        label: "名称",
        width: "200",
    },
    {
        prop: "address",
        label: "地址",
        width: "280",
    },
    {
        prop: "phone",
        label: "联系电话",
        width: "280",
    },
    {
        prop: "companyCode",
        label: "企业代码",
        width: "250",
    },
    {
        prop: "country",
        label: "国家",
        width: "180",
    },
    {
        prop: "aeoCode",
        label: "AEO代码",
        width: "180",
    },
];
const shanghaiRSNMini = [
    {
        prop: "nickName",
        label: "简称",
        width: "180",
    },
    {
        prop: "sendName",
        label: "发货人名称",
        width: "280",
    },
    // {
    //     prop: "sendPhone",
    //     label: "发货人电话",
    //     width: "250",
    // },
    {
        prop: "reviceName",
        label: "收货人名称",
        width: "280",
    },
    // {
    //     prop: "revicePhone",
    //     label: "收货人电话",
    //     width: "250",
    // },
    {
        prop: "noticeName",
        label: "通知人名称",
        width: "280",
    },
    // {
    //     prop: "noticePhone",
    //     label: "通知人电话",
    //     width: "250",
    // },
];
const shanghaiR = [
    {
        prop: "nickName",
        label: "自定义名称",
        width: "180",
    },
    {
        prop: "name",
        label: "名称",
        width: "150",
    },
    {
        prop: "address",
        label: "地址",
        width: "250",
    },
    {
        prop: "aeoCode",
        label: "AEO代码",
        width: "180",
    },
    {
        prop: "companyCode",
        label: "企业代码",
        width: "220",
    },
    {
        prop: "country",
        label: "国家",
        width: "150",
    },
    {
        prop: "phone",
        label: "联系电话",
        width: "200",
    },
    {
        prop: "contactName",
        label: "实际联系人",
        width: "150",
    },
    {
        prop: "contactPhone",
        label: "实际联系人电话",
        width: "200",
    },
];
const shanghaiRSN = [
    {
        prop: "nickName",
        label: "自定义名称",
        width: "180",
    },
    {
        prop: "sendName",
        label: "发货人名称",
        width: "180",
    },
    {
        prop: "sendPhone",
        label: "发货人电话",
        width: "250",
    },
    {
        prop: "reviceName",
        label: "收货人名称",
        width: "180",
    },
    {
        prop: "revicePhone",
        label: "收货人电话",
        width: "250",
    },
    {
        prop: "noticeName",
        label: "通知人名称",
        width: "180",
    },
    {
        prop: "noticePhone",
        label: "通知人电话",
        width: "250",
    },
];
const shopCompanyList = [
    {code: "COSCO", name: "中远海"},
    {code: "HPL", name: "赫伯罗特"},
    {code: "HBS", name: "汉堡南美"},
    {code: "MSK", name: "马士基"},
    {code: "ONE", name: "海洋网联"},
    {code: "PIL", name: "太平"},
    {code: "YML", name: "阳明"},
    {code: "ZIM", name: "以星"},
    {code: "EMC", name: "长荣海运"},
    {code: "ACI", name: "亚利安莎"},
    {code: "ACL", name: "大西洋"},
    {code: "APL", name: "美国总统"},
    {code: "ATL", name: "安通物流"},
    {code: "CCNI", name: "南美智利"},
    {code: "CKL", name: "天敬"},
    {code: "CMA", name: "法国达飞"},
    {code: "CNC", name: "正利"},
    {code: "CUL", name: "中联航运"},
    {code: "DEL", name: "达贸轮船"},
    {code: "DYS", name: "东暎"},
    {code: "ESL", name: "阿联酋航运"},
    {code: "FESCO", name: "远东船务"},
    {code: "GSL", name: "金星轮船"},
    {code: "HAL", name: "兴亚"},
    {code: "HMM", name: "现代"},
    {code: "IAL", name: "运达"},
    {code: "JINJIANG", name: "锦江"},
    {code: "KKC", name: "神原汽船"},
    {code: "KMTC", name: "高丽"},
    {code: "MARI", name: "玛丽亚那"},
    {code: "MATS", name: "美森轮船"},
    {code: "MCC", name: "穆勒航运"},
    {code: "MSC", name: "地中海航运"},
    {code: "MSH", name: "民生轮船"},
    {code: "NGPL", name: "太古船务"},
    {code: "NOSCO", name: "宁波远洋"},
    {code: "NSS", name: "南星海运"},
    {code: "OOCL", name: "东方海外"},
    {code: "PCL", name: "泛洲海运"},
    {code: "RCL", name: "宏海箱运"},
    {code: "SAF", name: "南非轮船"},
    {code: "SINOKOR", name: "长锦"},
    {code: "SINO", name: "中外运"},
    {code: "SLS", name: "海领船务"},
    {code: "SML", name: "森罗"},
    {code: "STX", name: "世腾船务"},
    {code: "SSL", name: "萨姆达拉"},
    {code: "TSL", name: "德翔海运"},
    {code: "USL", name: "美国轮船"},
    {code: "ZSH", name: "中谷海运"},
];
// 宁波舱单(舱单委托录入)收发通table
// 发货人，通知人
export const NBManifestDNTable = [
    {
        prop: "code",
        label: "代码",
        width: "100",
    },
    {
        prop: "name",
        label: "名称",
        width: "100",
        component: "textarea",
        componentProps: {
            autosize: {
                minRows: 2,
                maxRows: 6,
            },
        },
    },
    {
        prop: "phone",
        label: "电话",
        width: "180",
    },
    {
        prop: "address",
        label: "地址",
        component: "textarea",
        componentProps: {
            autosize: {
                minRows: 2,
                maxRows: 6,
            },
        },
    },
    {
        prop: "countrycode",
        label: "国家/地区代码",
        width: "100",
    },
    {
        prop: "aeocode",
        label: "AEO代码（选填）",
        width: "100",
    },
];

// 收货人
export const NBManifestSTable = [
    ...NBManifestDNTable,
    {
        prop: "contactname",
        label: "具体联系人",
        width: "100",
    },
    {
        prop: "contactphone",
        label: "联系人电话",
        width: "200",
    },
];

// 收发通概要
export const NBManifestITable = [
    {
        prop: "name",
        label: "姓名",
    },
    {
        prop: "phone",
        label: "电话",
    },
    {
        prop: "address",
        label: "地址",
    },
];

// 船代
export const NBManifestShipTable = ["外运", "兴港", "东南", "电子口岸"];
// 宁波舱单外部table
export const NBManifestOutLineTable = [
    {
        label: "提单号",
        prop: "billno",
    },
    {
        label: "受理",
        prop: "operuser",
    },
    {
        label: "状态",
        prop: "completionstatus",
    },
    {
        label: "船代",
        prop: "webtype",
    },
];
export default {
    sResetTable,
    shopCompanyList,
    resetTableNB,
    queryTable,
    queryTable2,
    queryTable3,
    shipTable,
    shipTableSH,
    manifestTable,
    rUserDataList,
    sUserDataList,
    manifestTableTitleList,
    shanghaiAlready,
    shanghaiUpdateDelete,
    shanghaiCounterDelete,
    shanghaiShipAgent,
    goodsDetail,
    shanghaiSN,
    shanghaiRSNMini,
    shanghaiR,
    shanghaiRSN,
    scompany,
    qdResetTable,
    shipTableQD,
    shipTableTJ,
    tjResetTable,
    xmResetTable,
    shipTableXM,
    vgmTableHeader,
    vgmContainerTable,
    szResetTable,
    shipTableSZ
};
