import { saveConfig, getConfig } from "@/api/common";
// key
const OPEN_KEY = `open_ads`;
// 默认值
export const DEFAULT_CUR_DETAIL_COUNT = {
    上海隽硕国际货物运输代理有限公司: 0,
    义乌市文鳐报关代理有限公司: 0,
};
// 获取广告显示次数信息
export const getOpenAdsConfig = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const defaultCurDetailCount = DEFAULT_CUR_DETAIL_COUNT;
            const configRes = await getConfig({ dictKey: OPEN_KEY });
            const { dictValue } = configRes.data.data;
            const cacheConfig = JSON.parse(dictValue);
            // 比较存储值和默认值的长度，如果不相等，说明有新增的数据，需要重置
            if (
                Object.keys(cacheConfig).length !==
                Object.keys(defaultCurDetailCount).length
            ) {
                await saveConfig({
                    dictKey: OPEN_KEY,
                    dictValue: JSON.stringify(defaultCurDetailCount),
                });
                resolve(defaultCurDetailCount);
            }
            // 去除无用的数据
            Object.keys(cacheConfig).forEach((key) => {
                if (defaultCurDetailCount[key] === undefined) {
                    delete cacheConfig[key];
                }
            });
            const copyObj = Object.assign({}, defaultCurDetailCount);
            let curDetailCount = {
                ...copyObj,
                ...cacheConfig,
            };
            resolve(curDetailCount);
        } catch (e) {
            console.log(e);
            // 没有配置信息
            reject(e);
        }
    });
};
