import request from "./http";

// 获取微信登录二维码
export const getWxQrCode = async () => {
    return await request.get(
        "https://prod-api.fangcangwang.com/manifest/wechat/wxticket",
    );
};

// 扫码登录
export const wxScanLogin = async (params) => {
    return await request.post(
        "https://prod-api.fangcangwang.com/manifest/wechat/scanlogin",
        params,
    );
};

/**
 * 账号密码登录
 * @param {string} account
 * @param {string} password
 * @returns
 */
export const pwdLogin = async (params) => {
    return await request.post("/auth/login", params, {}, false, true);
};

/**
 * 获取更新日志
 */
export const getUpdateLog = async () => {
    return await request.get(
        "/redis/getUpdateNote",
        {},
        {
            showErrorMessage: false,
        },
    );
};

/**
 * 存储一些配置数据
 * @param {String} dictKey
 * @param {String} dictValue
 */
export const saveConfig = (params) => {
    return request.post("/dict/set", params, {
        onlyJudgeCode: true,
    });
};

/**
 * 获取配置数据
 * @param {String} dictKey
 */
export const getConfig = (params) => {
    return request.post(
        "/dict/get",
        params,
        {
            onlyJudgeCode: true,
            showErrorMessage: false,
        },
        true,
    );
};

/**
 * 广告点击时调用
 * @param {String} adName
 */
export const adClick = async (data) => {
    return await request.post(
        "/ad/addRecord",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            showErrorMessage: false,
        },
        true,
    );
};

/**
 * 广告关闭时调用
 * @param {String} adId
 * @param {Number} stayTime
 */
export const adClose = async (data) => {
    return await request.post(
        "/ad/closeAd",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            showErrorMessage: false,
        },
        true,
    );
};
