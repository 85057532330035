<template>
    <div class="container">
        <iframe :src="pdfUrl" width="100%" height="100%"></iframe>
    </div>
</template>
<script>
const fileUrl =
    "https://web.nbhuojian.com/static/f1-customs-declaration-form/pdfjs-4.0.379-dist/web/viewer.html?file="; // pdfjs文件地址
export default {
    props: ["url"],
    data() {
        return {};
    },
    computed: {
        pdfUrl() {
            return fileUrl + encodeURIComponent(this.url);
        },
    },
};
</script>
<style scoped>
.container{
    height:100%;
    width:100%;
}
</style>
