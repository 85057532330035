const state = {
    webSocketMsg: "",
    systemKey: "NB", // NB-宁波、QD-青岛、SH-上海、大连-DL
    screenWidth: document.body.clientWidth,
    isLookAds: false, // 是否看过广告了
};

const mutations = {
    SET_WS_MSG: (state, msg) => {
        state.webSocketMsg = msg;
    }, //聊天室消息记录
    editSystemKey(state, payload) {
        state.systemKey = payload;
    },
    updateScreenWidth(state, payload) {
        state.screenWidth = payload;
    },
    setLookAds(state, payload) {
        state.isLookAds = payload;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
